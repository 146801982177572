import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from '../api/services/auth'
import type { RootState } from '.'
import Cookies from 'js-cookie'

type AuthState = {
  user: User | null
  token: string | null
  isEmailConfirm: boolean;
}

const accessToken = Cookies.get("accessToken")

const slice = createSlice({
  name: 'auth',
  initialState: { user: null, token: accessToken || null, isEmailConfirm: true } as AuthState,
  reducers: {
    setCredentials: (
      state,
      {
        payload: { userProfile, token },
      }: PayloadAction<{ userProfile: User; token: string }>,
    ) => {
      Cookies.set('accessToken', token)
      state.user = userProfile
      state.token = token
    },
    setUserProfile: (
      state,
      {
        payload: { userProfile },
      }: PayloadAction<{ userProfile: User;}>,
    ) => {
      console.log(userProfile);
      state.user = userProfile
    },
    setIsEmailConfirm: (
      state,
      {
        payload: isEmailConfirm ,
      }: PayloadAction<boolean>,
    ) => {
      state.isEmailConfirm = isEmailConfirm;
    },
    setToken: (
      state,
      {
        payload: token ,
      }: PayloadAction< string>,
    ) => {
      state.token = token
    },
    cleanCredentials: (
      state
    ) => {
      Cookies.remove('accessToken', { path: '' })
      state.user = null
      state.token = ''
    },
  },
})

export const { setCredentials, cleanCredentials, setUserProfile, setToken, setIsEmailConfirm} = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: RootState) => state.auth.user
export const selectIsEmailConfirm = (state: RootState) => state.auth.isEmailConfirm

