import React, { Suspense, useState } from 'react';
import {  Layout, Spin, theme } from 'antd';
import HeaderLayout from './Header';
import MenuSider from './menuSider';

const { Sider, Content } = Layout;

const LayoutFree: React.FC<{children: React.ReactElement, height: number}> = ({children, height}) => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

 
 
  return (
    <Layout >
        <Content
          style={{
            margin: '24px 16px 24px 16px',
            height: height - 48,
            overflow: 'auto',
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Suspense fallback={<Spin />}>
            {children}
          </Suspense>
        </Content>
    </Layout>
  );
};

export default LayoutFree;