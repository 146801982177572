import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import Cookies from 'js-cookie'
import { Group, Student, StudentsForTable, StudentsResponse } from '../types/studentsTypes'

type StudentsState = {
  students: Student[],
  groups: Group[]
}

const slice = createSlice({
  name: 'students',
  initialState: { students: [], groups: []} as StudentsState,
  reducers: {
    setStudents: (
      state,
      {
        payload: { students, groups },
      }: PayloadAction<StudentsResponse>
    ) => {
      state.students = students
    },
    
    studentAddToList: (
      state,
      {
        payload: student ,
      }: PayloadAction<Student>
    ) => {
      state.students?.push(student)
    },
    studentEditOnList: (
      state,
      {
        payload: student ,
      }: PayloadAction<Student>
    ) => {
      state.students = state.students.map(item => item._id !== student._id ? item : student)
    },
    setGroups: (
      state,
      {
        payload: { groups },
      }: PayloadAction<{ groups: Group[];}>
    ) => {
      state.groups = groups
    },
    groupAddToList: (
      state,
      {
        payload: { group, students }  ,
      }: PayloadAction<{group: Group, students: Student[]}>
    ) => {
      state.groups?.push(group)
      state.students = students
    },
    groupEditOnList: (
      state,
      {
        payload: { group, students } ,
      }: PayloadAction<{group: Group, students: Student[]}>
    ) => {
      state.groups = state.groups.map(item => item._id !== group._id ? item : group)
      state.students = students
    },
    studentRemoveFromList: (
      state,
      {
        payload: id ,
      }: PayloadAction<string>
    ) => {
      state.students = state.students.filter(item => item._id !== id)
    },
  },
})

export const { setStudents, studentRemoveFromList, studentAddToList, studentEditOnList, groupAddToList, setGroups, groupEditOnList  } = slice.actions

export default slice.reducer

export const selectStudents = (state: RootState) => state.students.students
export const selectGroups = (state: RootState) => state.students.groups

