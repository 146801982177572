import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import Cookies from 'js-cookie'
import { Group, Student, StudentsForTable, StudentsResponse } from '../types/studentsTypes'
import { Event, EventsResponse } from '../types/eventsTypes'

type EventsState = {
  events: Event[],
}

const slice = createSlice({
  name: 'events',
  initialState: { events: [] } as EventsState,
  reducers: {
    setEvents: (
      state,
      {
        payload: { events },
      }: PayloadAction<EventsResponse>
    ) => {
      state.events = events
    },
    eventsAddToList: (
      state,
      {
        payload: events ,
      }: PayloadAction<Event[]>
    ) => {
      for(const event of events)
      state.events?.push(event)
    },
    eventsEditOnList: (
      state,
      {
        payload: events ,
      }: PayloadAction<Event[]>
    ) => {
      for(const event of events)
        state.events = state.events.map(item => item._id !== event._id ? item : event)
    },
    eventAddToList: (
      state,
      {
        payload: event ,
      }: PayloadAction<Event>
    ) => {
      state.events?.push(event)
    },
    eventEditOnList: (
      state,
      {
        payload: event ,
      }: PayloadAction<Event>
    ) => {
      state.events = state.events.map(item => item._id !== event._id ? item : event)
    },
    eventsRemoveFromList: (
      state,
      {
        payload: ids ,
      }: PayloadAction<string[]>
    ) => {
      state.events = state.events?.filter(item =>  !ids.includes(item._id));
    },
  },
})

export const { setEvents, eventAddToList, eventEditOnList, eventsEditOnList, eventsAddToList, eventsRemoveFromList } = slice.actions

export default slice.reducer

export const selectEvents = (state: RootState) => state.events.events

