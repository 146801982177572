import {combineReducers, configureStore} from "@reduxjs/toolkit"
import authSlice from "./authSlice"
import cartSlice from "./cartSlice"
import errorSlice from "./errorSlice"
import { api } from "../api/services/auth"
import { apiStudents } from "../api/services/students"
import studentsSlice from "./studentsSlice"
import eventsSlice from "./eventsSlice"
import homeworkSlice from "./homeworkSlice"

import { apiEvents } from "../api/services/events"
import { apiHomeworks } from "../api/services/homeworks"
import { apiImages } from "../api/services/images"
import { apiRepetitors } from "../api/services/repetitors"
import repetitorsSlice from "./repetitorsSlice"
import { apiFinance } from "../api/services/finance"
import financeSlice from "./financeSlice"
import { apiPayments } from "../api/services/payments"
import paymentsSlice from "./paymentsSlice"

const rootReducer = combineReducers(
    {
        [api.reducerPath]: api.reducer,
        [apiStudents.reducerPath]: apiStudents.reducer,
        [apiEvents.reducerPath]: apiEvents.reducer,
        [apiHomeworks.reducerPath]: apiHomeworks.reducer,
        [apiImages.reducerPath]: apiImages.reducer,
        [apiRepetitors.reducerPath]: apiRepetitors.reducer,
        [apiFinance.reducerPath]: apiFinance.reducer,
        [apiPayments.reducerPath]: apiPayments.reducer,
        auth:authSlice,
        students: studentsSlice,
        repetitors: repetitorsSlice,
        events: eventsSlice,
        finance: financeSlice,
        homeworks: homeworkSlice,
        payments: paymentsSlice,
        error:errorSlice
    })

const store = configureStore(
    {
        reducer:rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(api.middleware)
        .concat(apiStudents.middleware)
        .concat(apiEvents.middleware)
        .concat(apiHomeworks.middleware)
        .concat(apiImages.middleware)
        .concat(apiFinance.middleware)
        .concat(apiRepetitors.middleware)
        .concat(apiPayments.middleware),


    }
)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export default store