import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { AnswerAddRequest, AnswerAddResponse, CommentAddResponse, CommentsResponse, HomeworkAddRequest, HomeworkAddResponse, HomeworkAssessmentRequest, HomeworkCopyRequest, HomeworkEditRequest, HomeworkEditResponse, HomeworkResponse, HomeworkSendRequest, HomeworkSendResponse, HomeworksResponse, HomeworkStatusChangeRequest, HomeworkStatusChangeResponse, IHomeworksFilter, ITasksFilter, TaskAddRequest, TaskAddResponse, TaskEditRequest, TaskEditResponse, TasksResponse, TaskStatusChangeRequest, TaskStatusChangeResponse } from '../../types/homeworksTypes'
import { API_PATH, BASE_URL } from '../../constants'
import { FinanceAddRequest, FinanceAddResponse, FinanceResponse } from '../../types/financeTypes'

export const apiFinance = createApi({
  reducerPath: 'apiFinance',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    financeAdd: builder.mutation<FinanceAddResponse, FinanceAddRequest>({
      query: (credentials) => ({
        url: 'finance/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    getFinance: builder.query<FinanceResponse, void>({
      query: () => {
        return `finance`;
      },
    }),
    getStudentFinance: builder.query<FinanceResponse, void>({
      query: () => {
        return `student/finance`;
      },
    }),
  }),
})

export const { 
 useFinanceAddMutation,
 useGetFinanceQuery,
 useGetStudentFinanceQuery
} = apiFinance
