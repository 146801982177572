import React from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu, Spin, theme } from 'antd';
import UserMenu from './userMenu';

const { Header } = Layout;

interface IHeaderProps{
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
}

const HeaderLayout: React.FC<IHeaderProps> = ({setCollapsed, collapsed}) => {

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const headerStyle: React.CSSProperties = {
    position: 'sticky',
    top: 0,
    padding: 0,
    zIndex: 100,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 25,
    borderBottom: '1px solid rgba(5, 5, 5, 0.06)',
    alignItems: 'center',
    background: colorBgContainer
  };

  return <Header style={headerStyle}>
    <Button
      type="text"
      icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      onClick={() => setCollapsed(!collapsed)}
      style={{
        fontSize: '16px',
        width: 64,
        height: 64,
      }}
    />

  <UserMenu />
</Header>
};

export default HeaderLayout;