import React, { useEffect } from 'react';
import { DownOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { cleanCredentials, selectCurrentUser} from '../../redux/authSlice';
import useGetCurrentUser from '../../api/hooks/useGetCurrentUser';

const UserMenu: React.FC = () => {
    const navigate = useNavigate();
    const user = useSelector(selectCurrentUser)
    const dispatch = useDispatch();

    const logOut = () => {
        console.log('logout')
        dispatch(cleanCredentials())
        navigate("/login");
    }
    const goToProfile = () => {
      navigate("/profile");
  }
    const items: MenuProps['items'] = [
       
      {
        label: <a onClick={goToProfile}><UserOutlined /> Профиль</a>,
        key: '1',
      },
        {
          type: 'divider',
      },
        {
          label: <a onClick={logOut}><LogoutOutlined /> Выйти</a>,
          key: '2',
        },
       
      ];

  return <Dropdown menu={{ items }} trigger={['click']}>
    <a onClick={(e) => e.preventDefault()}>
      <Space>
        { user?.surname ? user?.surname + ' ' + user?.name : user?.name }
        <UserOutlined />
        <DownOutlined />
      </Space>
    </a>
  </Dropdown>
};

export default UserMenu;
