import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import { Finance, FinanceResponse } from '../types/financeTypes'

type FinanceState = {
  finance: Finance[],
}

const slice = createSlice({
  name: 'finance',
  initialState: { finance: [] } as FinanceState,
  reducers: {
    setFinance: (
      state,
      {
        payload: { finance },
      }: PayloadAction<FinanceResponse>
    ) => {
      state.finance = finance
    },
    
    financeAddToList: (
      state,
      {
        payload: finance ,
      }: PayloadAction<Finance[]>
    ) => {
      state.finance?.unshift(...finance)
    },
   
  },
  
})

export const { 
  setFinance,
  financeAddToList
} = slice.actions

export default slice.reducer

export const selectFinance = (state: RootState) => state.finance.finance


