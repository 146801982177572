import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { GroupAddRequest, GroupEditRequest, GroupEditResponse, GroupsResponse, StudentAddRequest, StudentEditRequest, StudentResponse, StudentsResponse } from '../../types/studentsTypes'
import { API_PATH, BASE_URL } from '../../constants'



export const apiStudents = createApi({
  reducerPath: 'apiStudents',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  refetchOnMountOrArgChange: 10,
  endpoints: (builder) => ({
    studentAdd: builder.mutation<StudentResponse, StudentAddRequest>({
      query: (credentials) => ({
        url: 'student/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    studentEdit: builder.mutation<StudentResponse, StudentEditRequest>({
      query: (credentials) => ({
        url: 'student/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    studentDisactivate: builder.mutation<StudentResponse, string>({
      query: (id) => ({
        url: `student/disactivate`,
        method: 'PUT',
        body: { _id: id },
      }),
    }),
    studentActivate: builder.mutation<StudentResponse, string>({
      query: (id) => ({
        url: `student/activate`,
        method: 'PUT',
        body: { _id: id },
      }),
    }),
    getStudents: builder.query<StudentsResponse, {isActive: boolean | null}>({
      query: ( filter ) => {
        let searchParams = `?isActive=${(filter.isActive)}`;
        return 'students' + searchParams
      },
    }),
    groupAdd: builder.mutation<GroupEditResponse, GroupAddRequest>({
      query: (credentials) => ({
        url: 'group/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    groupEdit: builder.mutation<GroupEditResponse, GroupEditRequest>({
      query: (credentials) => ({
        url: 'group/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getGroups: builder.query<GroupsResponse, string>({
      query: () => 'groups',
    }),
  }),
})

export const { useGetStudentsQuery, useGetGroupsQuery, useGroupAddMutation, useGroupEditMutation, useStudentAddMutation, useStudentEditMutation, useStudentDisactivateMutation, useStudentActivateMutation } = apiStudents
