import React, { useEffect, useState } from 'react';
import { Button, Form, FormProps, message, Popover, Steps, StepsProps, theme } from 'antd';
import '../globalStyle.css';
import './style.css';

import Title from 'antd/es/typography/Title';
import RoleSelect from './roleSelect';
import { useLocation, useNavigate } from 'react-router-dom';
import { FieldType } from './types';
import { useRegistarationMutation } from '../../api/services/auth';
import RegistrationForm from './registrationForm';
import { errorHandler, FetchError } from '../../utils/errorHandler';
import { cleanCredentials, selectCurrentUser, setCredentials, setIsEmailConfirm } from '../../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import EmailActivation from './emailActivation';
import { useForm, useWatch } from 'antd/es/form/Form';



const EmailActivationPage: React.FC = ({}) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)
  const dispatch = useDispatch();
  const logOut = () => {
    console.log('logout')
    dispatch(cleanCredentials())
    navigate("/login");
}
  return (
    <>
        <Title level={2}>Активация Email</Title>
         <div ><EmailActivation email={user?.email ?? ""} /></div>
        <Button type="link" onClick={() => logOut()} className={'buttonSecond'}>
            Выход
        </Button> 
    </>
  );
};

export default EmailActivationPage;