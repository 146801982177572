import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { errorHandler, FetchError } from "../../utils/errorHandler"
import { useGetCurrentUserQuery } from "../services/auth"
import { setUserProfile } from "../../redux/authSlice"
import { useNavigate } from "react-router-dom"

const useGetCurrentUser = (accessToken: string | undefined) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, error, isLoading } = useGetCurrentUserQuery(undefined, {
    skip: !accessToken, // Пропускаем запрос, если нет accessToken
  })
        
  useEffect(() => {
    if(error){
      errorHandler(error as FetchError, navigate, dispatch)
    }else if(data){
      dispatch(setUserProfile(data))
    }
  }, [error, data])

    return isLoading;
}

export default useGetCurrentUser;