import { notification, Spin } from "antd";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useRouteLoaderData, useLocation } from "react-router-dom";
import { selectCurrentUser, selectIsEmailConfirm, setToken, setUserProfile } from "../../redux/authSlice";
import Cookies from "js-cookie";
import './style.css'
import LayoutFull from "./layoutFull";
import useWindowDimensions from "../../hooks/windowDimentions";
import LayoutFullMobile from "./layoutFullMobile";
import useGetCurrentUser from "../../api/hooks/useGetCurrentUser";
import LayoutFree from "./layoutFree";

export default function Layout() {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const accessToken = Cookies.get("accessToken");
  const isLoading = useGetCurrentUser(accessToken);

  const location = useLocation(); // Используем хук useLocation для получения текущего URL

  const user = useSelector(selectCurrentUser);
  const isEmailConfirm = useSelector(selectIsEmailConfirm);

  const { width, height } = useWindowDimensions();

  useEffect(() => {
    console.log("Current page:", location.pathname); // Выводим имя текущей страницы в консоль
  }, [location]);
  if (["/privacy", "/details", "/offer" ].includes(location.pathname) ){
    return (
      <LayoutFree height={height}>
      <>
        {contextHolder}
        <Outlet />
      </>
    </LayoutFree>
    );

  }
  if (accessToken && isEmailConfirm && user) {
    if(width > 400)
      return (
        <LayoutFull height={height}>
          <>
            {contextHolder}
            <Outlet />
          </>
        </LayoutFull>
      );
    else
      return (
        <LayoutFullMobile height={height}>
          <>
            {contextHolder}
            <Outlet />
          </>
        </LayoutFullMobile>
      );
  }
  return (
    <div className='containerTable' style={{height: window.innerHeight}}>
      <div className='containerCell' style={{height: window.innerHeight}}>
        <div className='form'>
          {contextHolder}
          <Suspense fallback={<Spin />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

