import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import { Payment, PaymentsResponse } from '../types/paymentsTypes'

type PaymentsState = {
  payments: Payment[],
}

const slice = createSlice({
  name: 'payments',
  initialState: { payments: [] } as PaymentsState,
  reducers: {
    setPayments: (
      state,
      {
        payload: { payments },
      }: PayloadAction<PaymentsResponse>
    ) => {
      state.payments = payments
    },
    
    paymentAddToList: (
      state,
      {
        payload: payments ,
      }: PayloadAction<Payment[]>
    ) => {
      state.payments?.unshift(...payments)
    },
   
  },
  
})

export const { 
  setPayments,
  paymentAddToList
} = slice.actions

export default slice.reducer

export const selectPayments= (state: RootState) => state.payments.payments


