import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { AnswerAddRequest, AnswerAddResponse, CommentAddResponse, CommentsResponse, HomeworkAddRequest, HomeworkAddResponse, HomeworkAssessmentRequest, HomeworkCopyRequest, HomeworkEditRequest, HomeworkEditResponse, HomeworkResponse, HomeworkSendRequest, HomeworkSendResponse, HomeworksResponse, HomeworkStatusChangeRequest, HomeworkStatusChangeResponse, IHomeworksFilter, ITasksFilter, TaskAddRequest, TaskAddResponse, TaskEditRequest, TaskEditResponse, TasksResponse, TaskStatusChangeRequest, TaskStatusChangeResponse } from '../../types/homeworksTypes'
import { API_PATH, BASE_URL } from '../../constants'
import { FinanceAddRequest, FinanceAddResponse, FinanceResponse } from '../../types/financeTypes'
import { CreatePaymentRequest, CreatePaymentResponse, PaymentsResponse } from '../../types/paymentsTypes'

export const apiPayments = createApi({
  reducerPath: 'apiPayments',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    createPayment: builder.mutation<CreatePaymentResponse, CreatePaymentRequest>({
      query: (credentials) => ({
        url: 'create-payment',
        method: 'POST',
        body: credentials,
      }),
    }),
    getPayments: builder.query<PaymentsResponse, void>({
      query: () => {
        return `payments`;
      },
    }),
  }),
})

export const { useCreatePaymentMutation, useGetPaymentsQuery
} = apiPayments
