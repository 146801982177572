import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { AnswerAddRequest, AnswerAddResponse, CommentAddResponse, CommentsResponse, HomeworkAddRequest, HomeworkAddResponse, HomeworkAssessmentRequest, HomeworkCopyRequest, HomeworkEditRequest, HomeworkEditResponse, HomeworkFilter, HomeworkResponse, HomeworkSendRequest, HomeworkSendResponse, HomeworksResponse, HomeworkStatusChangeRequest, HomeworkStatusChangeResponse, IHomeworksFilter, ITasksFilter, TaskAddRequest, TaskAddResponse, TaskEditRequest, TaskEditResponse, TasksResponse, TaskStatusChangeRequest, TaskStatusChangeResponse } from '../../types/homeworksTypes'
import { API_PATH, BASE_URL } from '../../constants'

export const apiHomeworks = createApi({
  reducerPath: 'apiHomeworks',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    homeworkSend: builder.mutation<HomeworkSendResponse, HomeworkSendRequest>({
      query: (credentials) => ({
        url: `homework/${credentials.templateId}/send`,
        method: 'POST',
        body: credentials,
      }),
    }),
    homeworkAdd: builder.mutation<HomeworkAddResponse, HomeworkAddRequest>({
      query: (credentials) => ({
        url: 'homework/add',
        method: 'POST',
        body: credentials,
      }),
    }),
    homeworkCopy: builder.mutation<HomeworkAddResponse, HomeworkCopyRequest>({
      query: ({ copyTemplateId, ...rest }) => ({
        url: `homework/${copyTemplateId}/copy`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
    taskAdd: builder.mutation<TaskAddResponse, TaskAddRequest>({
      query: ({ templateId, ...rest }) => ({
        url: `homework/${templateId}/task/add`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
    answerAdd: builder.mutation<AnswerAddResponse, AnswerAddRequest>({
      query: ({ taskId, ...rest }) => ({
        url: `homework/task/${taskId}/answer/add`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
    taskStatusDone: builder.mutation<TaskStatusChangeResponse, TaskStatusChangeRequest>({
      query: ({ taskId }) => ({
        url: `homework/task/${taskId}/status/done`,
        method: 'PUT',
        body: { },
      }),
    }),
    taskStatusReject: builder.mutation<TaskStatusChangeResponse, TaskStatusChangeRequest>({
      query: ({ taskId }) => ({
        url: `homework/task/${taskId}/status/reject`,
        method: 'PUT',
        body: { },
      }),
    }),
    homeworkStatusReject: builder.mutation<HomeworkStatusChangeResponse, HomeworkStatusChangeRequest>({
      query: ({ homeworkId }) => ({
        url: `homework/${homeworkId}/status/reject`,
        method: 'PUT',
        body: { },
      }),
    }),
    homeworkStatusToCheck: builder.mutation<HomeworkStatusChangeResponse, HomeworkStatusChangeRequest>({
      query: ({ homeworkId }) => ({
        url: `homework/${homeworkId}/status/to-check`,
        method: 'PUT',
        body: { },
      }),
    }),
    homeworkAssessment: builder.mutation<HomeworkStatusChangeResponse, HomeworkAssessmentRequest>({
      query: ({ homeworkId, assessment }) => ({
        url: `homework/${homeworkId}/assessment`,
        method: 'PUT',
        body: { assessment },
      }),
    }),
    commentAdd: builder.mutation<CommentAddResponse, AnswerAddRequest>({
      query: ({ taskId, ...rest }) => ({
        url: `homework/task/${taskId}/comment/add`,
        method: 'POST',
        body: { ...rest },
      }),
    }),
    homeworkEdit: builder.mutation<HomeworkEditResponse, HomeworkEditRequest>({
      query: (credentials) => ({
        url: 'homework/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    taskEdit: builder.mutation<TaskEditResponse, TaskEditRequest>({
      query: (credentials) => ({
        url: 'homework/task/edit',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getHomeworks: builder.query<HomeworksResponse, HomeworkFilter>({

      query: (filter) => {
        let queryParams = '';
        if (filter.dateRange) {
          queryParams += `?start=${filter.dateRange[0]?.toISOString()}&end=${filter.dateRange[1]?.toISOString()}`;
        }
        if (filter.studentId) {
          queryParams += `&studentId=${filter.studentId}`;
        }
        return `homeworks/${filter.status}${queryParams}`;
      },
    }),
    getHomeworksForStudent: builder.query<HomeworksResponse, string | undefined>({
      query: (key) => {
        return `homeworks_for_student/${key}`;
      },
    }),
    getTasks: builder.query<TasksResponse, string | undefined>({
      query: (templateId) => {
        return `homework/${templateId}/tasks`
      },
    }),
    getComments: builder.query<CommentsResponse, string | undefined>({
      query: (taskId) => {
        return `homework/task/${taskId}/comments`
      },
    }),
    getHomework: builder.query<HomeworkResponse, string | undefined>({
      query: (id) => {
        return `homework/${id}`
      },
    }),
    deleteHomework: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `homework/delete/${id}`,
          method: 'DELETE',
        }
      },
    }),
    deleteTask: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `homework/task/delete/${id}`,
          method: 'DELETE',
        }
      },
    }),
  }),
})

export const { 
  useGetHomeworksQuery, 
  useDeleteHomeworkMutation, 
  useDeleteTaskMutation, 
  useGetTasksQuery, 
  useGetHomeworkQuery, 
  useHomeworkAddMutation, 
  useTaskAddMutation, 
  useTaskEditMutation, 
  useHomeworkEditMutation, 
  useHomeworkSendMutation,
  useHomeworkCopyMutation,
  useGetHomeworksForStudentQuery,
  useAnswerAddMutation,
  useCommentAddMutation,
  useGetCommentsQuery,
  useTaskStatusDoneMutation,
  useTaskStatusRejectMutation,
  useHomeworkStatusRejectMutation,
  useHomeworkStatusToCheckMutation,
  useHomeworkAssessmentMutation
} = apiHomeworks
